<template>
  <div class="bus-integration">
    <section class="business-container">
      <div v-for="item in list" :key="item.label" class="card">
        <span v-if="item.isReiation" class="relation-tag" style="">已关联</span>
        <div class="logo-wrap">
          <img src="../../assets/qiwei.png" alt="" class="logo">
        </div>
        <p class="title">
          {{ item.appName }}
        </p>
        <p class="describe">
          {{ item.introduce || '暂无简介' }}
        </p>
        <div class="btn-wrap">
          <el-button type="primary" :plain="item.isReiation ? true : false" @click="goToDetail(item)">
            {{ item.isReiation ? '查看' : '关联' }}
          </el-button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getAppList } from '@/api/setting';
export default {
  data () {
    return {
      list: [],
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    async init () {
      const res = await getAppList();
      console.log(res);
      this.list = res;
    },
    goToDetail (item) {
      const query = {
        appId: item.appId,
        sns: item.sns,
      };
      this.$router.push({ path: '/bus-detail', query });
    },
  },
};
</script>
<style scoped lang='scss'>
.bus-integration {
  // background-color: #fff;
  .business-container {
    padding: 38px 12px 0 12px;
    overflow: auto;
    display: flex;
    .card {
      background-color: #fff;
      width: 258px;
      height: 291px;
      border: 1px solid #dee0e3;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0 20px;
      overflow: hidden;
      margin-right: 38px;
      text-align: center;
      margin-bottom: 38px;
      position: relative;
      .logo-wrap {
        height: 116px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .title {
        font-size: 14px;
        color: #1f2329;
        margin-bottom: 10px;
      }
      .describe {
        text-align: center;
        line-height: 18px;
        font-size: 12px;
        color: #646a73;
      }
      .btn-wrap {
        position: absolute;
        bottom: 30px;
        display: flex;
        justify-content: center;
        width: calc(100% - 40px);
      }
    }
    .relation-tag {
      color: #306eff;
      font-size: 12px;
      width: 100px;
      height: 40px;
      background: #eef1ff;
      display: inline-block;
      line-height: 50px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      right: -36px;
      top: -4px;
    }
  }
}
</style>